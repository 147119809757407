<template>
  <div
    v-html="$md(text)"
  />
</template>

<script>
export default defineComponent({
  name: 'Markdown',
  props: {
    text: {
      type: String,
      required: true
    }
  }
})
</script>
